.header {
  height: 120px;
  background-color: #46484a;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid lightgrey;
  color: white;
}

.header-text {
  font-size: 35px;
}

.logo {
  margin-right: 50px;
  max-height: 90px;
}

.back-to-forms {
  position: absolute;
  left: 10vw;
  top: 50px;
}

div.navigation > a,
.logo {
  height: 80%;
}

h1.page-title {
  margin-top: 30px;
  text-align: center;
}

.quick-links-container {
  margin-left: auto;
  margin-right: auto;
}

.quick-links-container > .department-header {
  color: white;
  background-color: #7c7d7e;
  width: fit-content;
  padding: 4px 8px 25px 8px;
  font-weight: 500;
  position: relative;
  border-radius: 10px;
  margin-right: 12px;
}

.quick-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 80vw;
  height: 75px;
  border-radius: 10px;
  background-color: #7c7d7e;
  color: white;
  font-size: 25px;
  margin-bottom: 30px;
  position: relative;
}

.quick-links > a {
  color: white;
}

.current-quick-link {
  color: blue !important;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  margin-top: 50px;
  height: 20%;
  width: 60%;
  text-align: center;
}

#form-card-container {
  width: 95vw;
  max-width: 1000px;
  /* margin-top: 50px; */
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  font-size: 1.5rem;
  text-align: center;
}

div.form-card {
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 100px;
  width: 15vw;
  max-width: 300px;
  height: 8vw;
  max-height: 175px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid grey;
  border-radius: 10px;
  color: white;
}

h2.form-card-title {
  font-size: 25px;
}

div.iframe-container {
  text-align: center;
  margin: 40px auto 80px auto;
}

div.iframe-container > iframe {
  max-width: 900px;
  width: 95vw;
}

@media screen and (max-width: 1000px) {
  div.form-card h2,
  .quick-links,
  .content h4 {
    font-size: 15px;
  }

  div.form-card {
    width: 150px;
    height: 75px;
  }
}
